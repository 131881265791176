.ant-modal-content{
  background-color: #302D2D !important; //background bên trang blog

  .mobile-preview {
      & img {
          max-width: 100% !important;
      }
      & img {
          max-width: 100% !important;
      }
  }
  
  &:not(:has(.mobile-preview)) {
      background-color: #fff !important; 
  }

}